import axios from "axios";

const baseUrl = process.env.REACT_APP_BASE_URL;

const notaLaqus = (dados) => {
    const url = `${baseUrl}/laqus/nota`;
    const headers = {
        'Content-Type': 'application/json',
    };
    axios.post(url, JSON.stringify(dados), { headers: headers })
        .then(response => {
           
            console.log(response.data);
            console.log(response);

        })
        .catch(error => {
            console.log(`Erro: ${error.response.data.error}`);
        });
}

const regularizaEmissor = (
    {userRef, emissor}
) => {
    const dados = {
        userRef, 
        emissor,
    };
    const url = `${baseUrl}/laqus/regulariza-cadastro-emissor`;
    const headers = {
        'Content-Type': 'application/json',
    };
    axios.post(url, JSON.stringify(dados), { headers: headers })
        .then(response => {
           
            console.log(response.data);
            console.log(response);

        })
        .catch(error => {
            console.log(`Erro: ${error.response.data.error}`);
        });
}

const regularizaInvestidor = (
    {userRef, investidor}
) => {
    const dados = {
        userRef,
        investidor
    };
    const url = `${baseUrl}/laqus/regulariza-cadastro-investidor`;
    const headers = {
        'Content-Type': 'application/json',
    };
    axios.post(url, JSON.stringify(dados), { headers: headers })
        .then(response => {
           
            console.log(response.data);
            console.log(response);

        })
        .catch(error => {
            console.log(`Erro: ${error.response.data.error}`);
        });
}

export  {
    notaLaqus,
    regularizaEmissor,
    regularizaInvestidor
};
