import { ClipLoader } from "react-spinners";

const LaqusButton = ({
    onClick,
    loaderCss,
    isLoading,
    buttonText,
    loadingText
}) => {
    const customStyle = {
        textAlign: 'center',
        color: '#ffffff',
        border: 'none',
        padding: '10px',
        cursor: 'pointer',
    }
    return (
        <>
            {isLoading ? (
                <button style={customStyle}>
                    <div>
                        <h1
                            style={{
                                fontSize: '14px'
                            }}
                        >
                            {loadingText} {'\n'}
                        </h1>
                        <ClipLoader
                            color="#ffffff"
                            loading={isLoading}
                            css={loaderCss}
                            size={15}
                        />
                    </div>
                </button>
            ) : (
                <button
                    style={customStyle}
                    onClick={onClick}
                >
                    {buttonText}
                </button>
            )}
        </>
    )
}

export default LaqusButton;