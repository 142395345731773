import React, { useEffect, useRef, useState } from "react";
import Modal from "react-modal";
import { formatCnpj, formatCpf, formatCurrency, isValidCPF, isValidEmail, formatFileSize  } from "../../utils/Tools";
import { RepresentanteItem, formatBankAccount, formatarTelefone } from "../../pages/Investidor";
import axios from "axios";
import { NumericFormat } from "react-number-format";
import { IoIosCloudUpload } from 'react-icons/io';

Modal.setAppElement("#root"); // Define o elemento raiz da aplicação para o modal

const InvestorModal = ({ isOpen, onClose, investorData }) => {
    const [tipoDeEmpresa, setTipoDeEmpresa] = useState(investorData ? investorData?.tipoDeEmpresa : "");
    const [razaoSocial, setRazaoSocial] = useState(investorData ? investorData?.razaoSocial : "");
    const [cnpj, setCnpj] = useState(investorData?.cnpj);
    const [cep, setCep] = useState(investorData?.cep);
    const [credorRua, setCredorRua] = useState(investorData?.rua);
    const [credorNumero, setCredorNumero] = useState(investorData?.numero);
    const [credorComplemento, setCredorComplemento] = useState(investorData?.complemento);
    const [loading, setLoading] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);
    const [credorBairro, setCredorBairro] = useState(investorData?.bairro);
    const [credorCidade, setCredorCidade] = useState(investorData?.cidade);
    const [credorEstado, setCredorEstado] = useState(investorData?.estado);
    const [email, setEmail] = useState(investorData?.email);
    const [telefone, setTelefone] = useState(investorData?.telefone);
    const [selectedBank, setSelectedBank] = useState(investorData?.banco);
    const [bankNumber, setBankNumber] = useState(investorData?.numeroDoBanco);
    const [conta, setConta] = useState(investorData?.conta);
    const [agency, setAgency] = useState(investorData?.agencia);
    const [tipoDeInstituicao, setTipoDeInstituicao] = useState(investorData?.tipoDeInstituicao??"FIDC");
    const [custodiante, setCustodiante] = useState(investorData?.custodiante);
    const [nomeCustodiante, setNomeCustodiante] = useState(investorData?.nomeCustodiante??"");
    const [cnpjCustodiante, setCnpjCustodiante] = useState(investorData?.cnpjCustodiante??"");
    const [dadosBancarios, setDadosBancarios] = useState('');
    const [banks, setBanks] = useState([]);
    const [representantes, setRepresentantes] = useState(investorData?.representantes);
    const [atividadePrincipal, setAtividadePrincipal] = useState(investorData?.atividadePrincipal);
    const [faturamentoMedioMensal12Meses, setFaturamentoMedioMensal12Meses] = useState(investorData?.faturamentoMedioMensal12Meses??'0');
    const [realFaturamento, setRealFaturamento] = useState('');
    const inputRefRealFaturamento = useRef(null);
    const [conSocial, setConSocial] = useState('');
    const inputRefConSocial = useRef(null);
    const [compEndereco, setCompEndereco] = useState('');
    const inputRefCompEndereco = useRef(null);
    
    const [editedData, setEditedData] = useState({ ...investorData });

    useEffect(() => {
        setEditedData({ ...investorData });
    }, [investorData]);
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setEditedData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };
    useEffect(() => {
        fetch('https://brasilapi.com.br/api/banks/v1')
            .then(response => response.json())
            .then(data => setBanks(data))
            .catch(error => console.error('Ocorreu um erro ao buscar os bancos:', error));
        setDadosBancarios(`Banco: ${selectedBank}-${bankNumber}/Agencia: ${agency}/Conta: ${conta}`);
    }, [dadosBancarios]);

    const handleSearch = () => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 2000);
    }

    const addRepresentante = () => {
        setRepresentantes([...representantes,
            { 
                nome: '', 
                email: '', 
                cpf:'', 
                procurador:0, 
                idFile: "",
                procuracaoFile: "",
            }
        ]); // Adiciona um novo representante vazio
    };

    const removeRepresentante = (index) => {
        const updatedRepresentantes = [...representantes];
        updatedRepresentantes.splice(index, 1);
        setRepresentantes(updatedRepresentantes);
    };
    const handleBankChange = (event) => {
        const selectedBankName = event.target.value;
        setSelectedBank(selectedBankName);
        const selectedBankData = banks.find(bank => bank.name === selectedBankName);

        if (selectedBankData) {
            setBankNumber(selectedBankData.code);
        }
        else if (selectedBankName === 'BNK Digital') {
            setBankNumber(547);
        } else {
            setBankNumber('');
        }
    };
    const keywordsToPrioritize = ['caixa eco', 'itaú uni', 'bco do brasil', 'bradesco s', 'santander', 'original', 'banco inter', 'bdigital'];

    const sortedBanks = [...banks].sort((a, b) => {
        const bankNameA = a.name ? a.name.toLowerCase() : '';
        const bankNameB = b.name ? b.name.toLowerCase() : '';

        const priorityA = getPriority(bankNameA);
        const priorityB = getPriority(bankNameB);

        if (priorityA !== priorityB) {
            return priorityA - priorityB; // Coloque o banco com prioridade maior primeiro
        } else {
            return bankNameA.localeCompare(bankNameB); // Ordem alfabética padrão
        }
    });
    const handleRepresentanteChange = (index, field, value) => {
        const updatedRepresentantes = [...representantes];
        updatedRepresentantes[index][field] = value;
        setRepresentantes(updatedRepresentantes);
    };
    function getPriority(name) {
        for (let i = 0; i < keywordsToPrioritize.length; i++) {
            if (name.includes(keywordsToPrioritize[i])) {
                return i; // Retorna a posição da primeira correspondência encontrada
            }
        }
        return keywordsToPrioritize.length; // Se não houver correspondências, use a prioridade máxima
    }
    const validateInputs = () => {
        
        if (!razaoSocial || razaoSocial === "") {
            alert('Por favor, insira uma Razão Social válida.');
            return false;
        }

        if (!cnpj || cnpj.replace(/\D/g, '').length !== 14) {
            alert('Por favor, insira um CNPJ válido.');
            return false;
        }

        if (!cep || cep.replace(/\D/g, '').length !== 8) {
            alert('Por favor, insira um CEP válido.');
            return false;
        }

        if (!credorRua || credorRua.trim() === "") {
            alert('Por favor, insira uma Rua válida.');
            return false;
        }

        if (!credorNumero) {
            alert('Por favor, insira um Número válido.');
            return false;
        }

        if (!credorBairro) {
            alert('Por favor, insira um Bairro válido.');
            return false;
        }

        if (!credorCidade) {
            alert('Por favor, insira uma Cidade válida.');
            return false;
        }

        if (!credorEstado) {
            alert('Por favor, insira um Estado válido.');
            return false;
        }
        if (!representantes || representantes.length === 0) {
            alert('Por favor, insira um Representante válido.');
            return false;
        }
        
        if(!validateInputsRepresentantes()){
            return false
        }

        if (!telefone) {
            alert('Por favor, insira um Número de Telefone válido.');
            return false;
        }

        if (!selectedBank) {
            alert('Por favor, selecione um Banco.');
            return false;
        }

        if (!bankNumber) {
            alert('Por favor, insira um Número de Banco válido.');
            return false;
        }

        if (!agency) {
            alert('Por favor, insira uma Agência válida.');
            return false;
        }

        if (!conta) {
            alert('Por favor, insira um Número de Conta válido.');
            return false;
        }

        if (!cnpj || cnpj.replace(/\D/g, '').length !== 14) {
            alert('Por favor, insira um CNPJ válido.');
            return false;
        }
        if(tipoDeInstituicao=='FIDC'){
            if (!nomeCustodiante) {
                alert('Por favor, insira um Nome do Custodiante.');
                return false;
            }
    
            if (!cnpjCustodiante || cnpjCustodiante.replace(/\D/g, '').length !== 14) {
                alert('Por favor, insira um CNPJ do Custodiante válido.');
                return false;
            }
        }
        return true; // Todos os campos estão válidos
    };

    const validateInputsRepresentantes = () =>{
        return representantes.every((rep, index)=>{
         let nome = rep.nome?.trim()??"";
         let email = rep.email?.trim()??"";
         let cpf = rep.cpf?.trim()??"";
             if(nome.length<=2){
                 alert(`Representante ${index+1}º deve ter um nome maior que dois caracteres`);
                 return false;
             }
             if(!isValidEmail(email)){
                 alert(`Representante ${index+1}º deve ter um e-mail válido.`);
                 return false;
             }
             if(!isValidCPF(cpf)){
                 alert(`Representante ${index+1}º deve ter um CPF válido.`);
                 return false;
             }
             return true;
         })
     }
     const handleFileChange = (e, fileType) => {
        const selectedFiles = e.target.files;
        const file = selectedFiles[0];

        const inputFile = {
            faturamento: () => {
                setRealFaturamento(file);
            },
            contratos: () => {
                setConSocial(file);
            },
            comprovante: () => {
                setCompEndereco(file);
            },
        };

        const input = inputFile[fileType]?inputFile[fileType]():null;
    };

    const handleRemoverArquivo = (fileType) => {
        const inputFile = {
            faturamento: () => {
                setRealFaturamento('');
                inputRefRealFaturamento.current.value = "";
            },
            contratos: () => {
                setConSocial('');
                inputRefConSocial.current.value = "";
            },
            comprovante: () => {
                setCompEndereco('');
                inputRefCompEndereco.current.value = "";
            },
        };

        const input = inputFile[fileType]?inputFile[fileType]():null;
    };

    const handleRepresentanteFileChange = (index, field, e) => {
        const selectedFiles = e.target.files;
        const file = selectedFiles[0];
        const updatedRepresentantes = [...representantes];
        updatedRepresentantes[index][field] = file;
        setRepresentantes(updatedRepresentantes);
    };

    const handleRepresentanteRemoverArquivo = (index, field) =>{
        const updatedRepresentantes = [...representantes];
        updatedRepresentantes[index][field] = '';
        setRepresentantes(updatedRepresentantes);
    }

    const handleSave = (event) => {
        event.preventDefault();
        if(!validateInputs()){
            return false;
        }

        const formData = new FormData();
        formData.append('razaoSocial', razaoSocial);
        formData.append('cnpj', cnpj);
        formData.append('cep', cep);
        formData.append('rua', credorRua);
        formData.append('bairro', credorBairro);
        formData.append('numero', credorNumero);
        formData.append('complemento', credorComplemento);
        formData.append('cidade', credorCidade);
        formData.append('estado', credorEstado);
        // Adicione representantes (supondo que representantes é um array de objetos)
        representantes.forEach((representante, index) => {
            formData.append(`representantes[${index}][nome]`, representante.nome);
            formData.append(`representantes[${index}][email]`, representante.email);
            formData.append(`representantes[${index}][cpf]`, representante.cpf);
            formData.append(`representantes[${index}][procurador]`, representante.procurador);
            if(representante.procuracaoFile){
                console.log("Teste")
            }
            console.log(
                "representante.procuracaoFile",
                representante.procuracaoFile
            );
            console.log(
                "representante.idFile",
                representante.idFile
            );
            
            if(representante.procuracaoFile){
                console.log("procuracaoFile")
                formData.append(`representantesProcuracaoFile`, representante.procuracaoFile);
                formData.append(`representantesProcuracaoFileIndex`, index);
            }else{
                formData.append(`representantes[${index}][procuracao]`, representante.procuracao);
            }
            if(representante.idFile){
                console.log("idFile")
                formData.append(`representantesIdFile`, representante.idFile);
                formData.append(`representantesIdFileIndex`, index);
            }else{
                formData.append(`representantes[${index}][idDocumento]`, representante.idDocumento);
            }
            

        });

        formData.append('telefone', telefone);
        formData.append('banco', selectedBank);
        formData.append('numeroDoBanco', bankNumber);
        formData.append('conta', conta);
        formData.append('agencia', agency);
        formData.append('nomeCustodiante', nomeCustodiante);
        formData.append('cnpjCustodiante', cnpjCustodiante);
        formData.append('atividadePrincipal', atividadePrincipal);
        formData.append('faturamentoMedioMensal12Meses', faturamentoMedioMensal12Meses);
       // Adicione os arquivos
        formData.append('realFaturamentoFile', realFaturamento);
        formData.append('conSocialFile', conSocial);
        formData.append('compEnderecoFile', compEndereco);

        console.log("Editado");
        // Envia os dados atualizados para a API
        const headers = {
            'Content-Type': 'multipart/form-data'
        } //axios.put(`https://c2easybe-production.up.railway.app/investidor/${investorData._id}`, editedData)
        
        axios.post(`${process.env.REACT_APP_BASE_URL}/investidor/UpdateinvestidorNew/${investorData._id}`, 
            formData,
            headers
        ).then((response) => {
            window.alert('Dados atualizados com sucesso!');
            //onClose();
        }).catch((error) => {
            // Lógica para lidar com erros na requisição
            console.error("Erro ao atualizar dados do investidor:", error);
        });
        
    };

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onClose}
            contentLabel="Investor Modal"
        >
            <div className="modalBody">
                <h2>Detalhes do Investidor</h2>
                <form className="formDoc1">
                    <div className="divTitleDoc">
                        <h1 className="h1Doc">INVESTIDOR</h1>
                    </div>
                    <div className={`divInputsDoc`}>
                        <label htmlFor="" className="labelInputsDoc">
                                        Tipo de empresa:
                        </label>
                        <select
                            className={`inputsDoc`} style={{ width:"98%"}}
                            value={tipoDeEmpresa}
                            onChange={(e) => setTipoDeEmpresa( e.target.value)}
                        >
                            <option value="Limitada">Limitada </option>
                            <option value="SA">SA</option>
                        </select>
                    </div>
                    <div className={`divInputsDoc`}>
                        <label htmlFor="" className="labelInputsDoc">
                            Razão Social:
                        </label>
                        <input
                            type="text"
                            className={`inputsDoc`}
                            value={razaoSocial}
                            onChange={(e) => { setRazaoSocial(e.target.value) }}
                            maxLength={120} />
                    </div>
                    <div className="divInputsDoc">
                        <label htmlFor="" className="labelInputsDoc">
                            CNPJ:
                        </label>
                        <input
                            type="text"
                            className={`inputsDoc`}
                            value={formatCnpj(cnpj)}
                            onChange={(e) => { setCnpj(e.target.value) }}
                            maxLength={18}
                        />
                    </div>
                    <div className="divInputsDoc">
                        <label htmlFor="" className="labelInputsDoc">
                            CEP:
                        </label>
                        <input
                            type="text"
                            className={`inputsDoc`}
                            value={cep}
                            onChange={(e) => { setCep(e.target.value) }}
                            onBlur={handleSearch} />{loading && <p width="10%">Buscando...</p>}
                    </div>
                    <div className="divInputsDoc">
                        <label htmlFor="" className="labelInputsDoc">
                            Rua:
                        </label>
                        <input
                            type="text"
                            className={`inputsDoc`}
                            value={credorRua}
                            onChange={(e) => { setCredorRua(e.target.value) }}
                        />
                    </div>
                    <div className="divInputsDoc">
                        <label htmlFor="" className="labelInputsDoc">
                            Número:
                        </label>
                        <input
                            type="number"
                            className={`inputsDoc`}
                            value={credorNumero}
                            onChange={(e) => { setCredorNumero(e.target.value) }}
                        />
                    </div>
                    <div className="divInputsDoc">
                        <label htmlFor="" className="labelInputsDoc">
                            Complemento:
                        </label>
                        <input
                            type="text"
                            className={`inputsDoc`}
                            value={credorComplemento}
                            onChange={(e) => { setCredorComplemento(e.target.value) }}
                        />
                    </div>
                    <div className="divInputsDoc">
                        <label htmlFor="" className="labelInputsDoc">
                            Bairro:
                        </label>
                        <input
                            type="text"
                            className={`inputsDoc`}
                            value={credorBairro}
                            onChange={(e) => { setCredorBairro(e.target.value) }}
                        />
                    </div>
                    <div className="divInputsDoc">
                        <label htmlFor="" className="labelInputsDoc">
                            Cidade:
                        </label>
                        <input
                            type="text"
                            className={`inputsDoc`}
                            value={credorCidade}
                            onChange={(e) => { setCredorCidade(e.target.value) }}
                        />
                    </div>
                    <div className="divInputsDoc">
                        <label htmlFor="" className="labelInputsDoc">
                            Estado:
                        </label>
                        <input
                            type="text"
                            className={`inputsDoc`}
                            value={credorEstado}
                            onChange={(e) => { setCredorEstado(e.target.value) }}
                        />
                    </div>
                    <div className="divTitleDoc">
                        <h1 className="h1Doc">REPRESENTANTES</h1>
                    </div>

                    {representantes.map((representante, index) => (
                        <>
                            <RepresentanteItem 
                                representante ={representante} 
                                index={index} 
                                remove={removeRepresentante} 
                                handleChange = {handleRepresentanteChange}
                                handleFileChange={handleRepresentanteFileChange}
                                handleRemoverArquivo={handleRepresentanteRemoverArquivo}
                                countRepresentante={representantes.length}
                            />
                        </>
                    ))}
                    <div style={{width:"100%",textAlign: "center"}}>
                        <button type="button" className="buttonDoc" onClick={addRepresentante}>
                            + Adicionar Representante
                        </button>
                    </div>


                    <div className="divTitleDoc">
                        <h1 className="h1Doc1">Informações Complementares</h1>
                    </div>
                    <div className="divInputsDoc">
                            <label htmlFor="" className="labelInputsDoc">
                                Atividade Principal:
                            </label>
                            <input
                                type="text"
                                className={`inputsDoc`}
                                value={atividadePrincipal}
                                onChange={(e) => { setAtividadePrincipal(e.target.value) }}
                                maxLength={150} />
                        </div>
                    <div className="divInputsDoc">
                            <label htmlFor="" className="labelInputsDoc">
                                Faturamento Medio Mensal 12 Meses:
                            </label>
                            <NumericFormat
                                className={`inputsDoc`}
                            thousandSeparator={'.'}
                            decimalSeparator={','}
                            decimalScale={0}
                            fixedDecimalScale={true}
                            prefix={'R$ '}
                            allowNegative={false}
                            value={formatCurrency(faturamentoMedioMensal12Meses)}
                            onValueChange={(values) => {
                                if(values.value == '') values.value = '0';
                                setFaturamentoMedioMensal12Meses(values.value);
                            }}
                        />
                    </div>
                    <div className="divInputsDoc">
                        <label htmlFor="" className="labelInputsDoc">
                            Telefone:
                        </label>
                        <input
                            type="text"
                            className={`inputsDoc`}
                            value={formatarTelefone(telefone)}
                            onChange={(e) => { setTelefone(e.target.value) }}
                            maxLength={15} />
                    </div>
                    <div className="divInputsDoc">
                        <label htmlFor="" className="labelInputsDoc">
                            Dados Bancários
                        </label>
                        <select value={selectedBank} onChange={handleBankChange} className="selectDoc">
                            <option value="" className="optionInputsDoc">Selecione um banco</option>
                            <option value="BNK Digital" className="optionInputsDoc">BNK Digital</option>
                            {sortedBanks.map((bank, index) => (
                                <option key={index} value={bank.name} className="optionInputsDoc">
                                    {bank.name}
                                </option>
                            ))}
                        </select>

                    </div>
                    <div className="divInputsDoc">
                        <label htmlFor="" className="labelInputsDoc">Numero do Banco</label>
                        <input
                            type='text'
                            name="Número do banco"
                            placeholder="Selecione um banco"
                            className={`inputsDoc`}
                            value={bankNumber}
                            onChange={(e) => { setBankNumber(e.target.value) }}
                            maxLength={20}
                        />
                    </div>
                    <div className="divInputsDoc">
                        <label htmlFor="" className="labelInputsDoc">
                            Agência:
                        </label>
                        <input
                            placeholder="12345"
                            type="text"
                            className={`inputsDoc`}
                            value={agency}
                            onChange={(e) => { setAgency(e.target.value) }}
                            maxLength={8} />
                    </div>
                    <div className="divInputsDoc">
                        <label htmlFor="" className="labelInputsDoc">Conta</label>
                        <input
                            placeholder="123 456-X"
                            type="text"
                            className={`inputsDoc`}
                            value={formatBankAccount(conta)}
                            onChange={(e) => { setConta(e.target.value) }}
                            maxLength={13} />
                    </div>
                    <div className="divInputsDoc">
                        <label htmlFor="" className="labelInputsDoc">
                            Tipo de Instituição:
                        </label>
                        <select
                            className="selectDoc"
                            value={tipoDeInstituicao}
                            onChange={(e) => setTipoDeInstituicao(e.target.value)}
                        >
                            <option value="FIDC">FIDC</option>
                            <option value="Factoring">Factoring</option>
                            <option value="Securitizadora">Securitizadora</option>
                        </select>
                    </div>
                    {tipoDeInstituicao=='FIDC'?
                    <>
                    <div className="divInputsDoc">
                        <label htmlFor="" className="labelInputsDoc">
                            Nome do Custodiante:
                        </label>
                        <input
                            placeholder=""
                            type="text"
                            className={`inputsDoc`}
                            value={nomeCustodiante}
                            onChange={(e) => setNomeCustodiante(e.target.value)}
                            maxLength={100} />
                    </div>
                    <div className="divInputsDoc">
                        <label htmlFor="" className="labelInputsDoc">
                            CNPJ do Custodiante:
                        </label>
                        <input
                            placeholder=""
                            type="text"
                            className={`inputsDoc`}
                            value={formatCnpj(cnpjCustodiante)}
                            onChange={(e) => setCnpjCustodiante(e.target.value)}
                            maxLength={18} />
                    </div>
                    <div className="divInputsDoc">
                            <label className={"customFileInput2"} style={{marginRight: "25px"}}>
                                <input type="file" accept=".pdf, .doc, .docx" onChange={(e) => { handleFileChange(e, 'faturamento') }} ref={inputRefRealFaturamento} style={{ display: "none" }}/>
                                <IoIosCloudUpload className="uploadIcon" />
                                <span className="uploadText" style={{ textAlign: 'center', width: "100%" }}>
                                    Relatório de Faturamento
                                </span>
                                {(investorData?.realFaturamento)?
                                <span className="badgeFileInput">
                                    1
                                </span>
                                :""}
                            </label>
                    </div>
                    <div className="divInputsDoc">
                            <label className={"customFileInput2"} style={{marginRight: "25px"}}>
                                <input type="file" accept=".pdf, .doc, .docx" onChange={(e) => handleFileChange(e, 'contratos')}  ref={inputRefConSocial} style={{ display: "none" }}/>
                                <IoIosCloudUpload className="uploadIcon" />
                                <span className="uploadText" style={{ textAlign: 'center', width: "100%" }}>
                                    Contrato Social
                                </span>
                                {(investorData?.conSocial)?
                                <span className="badgeFileInput">
                                    1
                                </span>
                                :""}
                            </label>
                    </div>

                    <div className="divInputsDoc">
                        <label className={"customFileInput2"} style={{marginRight: "25px"}}>
                            <input type="file" accept=".pdf, .doc, .docx" onChange={(e) => handleFileChange(e, 'comprovante')}  ref={inputRefCompEndereco} style={{ display: "none" }}/>
                            <IoIosCloudUpload className="uploadIcon" />
                            <span className="uploadText" style={{ textAlign: 'center', width: "100%" }}>
                                Comprovante de Endereço
                            </span>
                            {(investorData?.compEndereco)?
                            <span className="badgeFileInput">
                                1
                            </span>
                            :""}
                        </label>
                    </div>
                    <div className="uploadedFilesList" style={{ marginTop: "15px" }}>
                            {realFaturamento ? (
                                <div className="uploadedFileItem">
                                     <span>{'Faturamento'}</span>
                                    <span>{realFaturamento.name}</span>
                                    <span>{formatFileSize(realFaturamento.size)}</span>
                                    <button onClick={() => handleRemoverArquivo('faturamento')} className="button11">Remover</button>
                                </div>
                            ) : (
                                <div>
                                   {/* <span>Nenhum arquivo enviado ainda.</span>*/}
                                </div>
                            )}
                        </div>
                        <div className="uploadedFilesList" style={{ marginTop: "15px" }}>
                            {conSocial ? (
                                <div className="uploadedFileItem">
                                     <span>{'Contrato Social'}</span>
                                    <span>{conSocial.name}</span>
                                    <span>{formatFileSize(conSocial.size)}</span>
                                    <button onClick={() => handleRemoverArquivo('contratos')} className="button11">Remover</button>
                                </div>
                            ) : (
                                <div>
                                   {/* <span>Nenhum arquivo enviado ainda.</span>*/}
                                </div>
                            )}
                        </div>
                        <div className="uploadedFilesList" style={{ marginTop: "15px" }}>
                            {compEndereco ? (
                                <div className="uploadedFileItem">
                                     <span>{'Comprovate de Endereço'}</span>
                                    <span>{compEndereco.name}</span>
                                    <span>{formatFileSize(compEndereco.size)}</span>
                                    <button onClick={() => handleRemoverArquivo('comprovante')} className="button11">Remover</button>
                                </div>
                            ) : (
                                <div>
                                   {/* <span>Nenhum arquivo enviado ainda.</span>*/}
                                </div>
                            )}
                        </div>
                    </>
                        :""}
                </form>
                <div style={{ display: 'flex', justifyContent: 'center' }}>

                    <button className="button" onClick={handleSave}>Salvar</button>
                    <button className="button" onClick={onClose}>Cancelar</button>
                </div>
            </div>
        </Modal>
    );
};

export default InvestorModal;
